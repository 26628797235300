import * as R from 'ramda';
import { createSelector } from 'reselect';
import _isArray from 'lodash/isArray';

import {
  planAppStates,
  PLAN_APP_STATES_OPEN_FOR_ORG,
  PLAN_APP_STATES_UNLOCKED_FOR_ORG,
  PLAN_APP_STATES_COMPLETED,
} from 'sow/constants/planApp';
import { sampleRanks } from 'sow/constants/sampleConstants';
import { coerceToInt, createSafeCachedSelector, propSelector } from 'sow/utils/selectors';
import { makeCompositeKey } from 'sow/store/modules/entities/schemas/util';
import { selectors as fromEntities } from 'sow/store/modules/entities';
import { selectors as fromRouter } from 'sow/store/modules/router';
import { showAcaUI } from 'sow/selectors/currentUser';

// pass it state string
export const isStateUnlocked = R.contains(R.__, PLAN_APP_STATES_UNLOCKED_FOR_ORG);
export const isStateLocked = R.complement(isStateUnlocked);
export const isStateOpen = R.contains(R.__, PLAN_APP_STATES_OPEN_FOR_ORG);
export const isStateClosed = R.complement(isStateOpen);

// Sort Functions
// TODO move this to shared utility module
const sortByPropDesc = prop => R.sortWith([R.descend(R.prop(prop))]);
const sortByIdPropDesc = sortByPropDesc('id');

// --
// -- Id Selectors
// --

// Prop ID Selectors
export const orgIdProp = propSelector('orgId');
export const changeIdProp = propSelector('changeId');
export const changeCommentIdProp = propSelector('changeCommentId');
export const changeRequestIdProp = propSelector('changeRequestId');
export const planAppIdProp = propSelector('planAppId');
export const worksheetIdProp = propSelector('worksheetId');
export const questionIdProp = propSelector('questionId');
export const matrixRowIdProp = propSelector('matrixRowId');
export const locationIdProp = propSelector('locationId');

// Composite ID Selectors
export const changeId = createSelector([changeIdProp], coerceToInt);
export const changeCommentId = createSelector([changeCommentIdProp], coerceToInt);

export const questionId = createSelector([questionIdProp], R.identity);
export const matrixRowId = createSelector([matrixRowIdProp], R.identity);

export const orgId = createSelector(
  [orgIdProp, fromRouter.paramOrgId],
  (propId, paramId) => coerceToInt(propId || paramId),
);

export const currentWorksheetId = createSelector(
  [worksheetIdProp, fromRouter.paramWorksheetId],
  (propId, paramId) => propId || paramId,
);

/**
 * Create cached selector for worksheetId. This caches the selector based on the
 * passed in props.
 */
export const worksheetId = createSafeCachedSelector(
  [currentWorksheetId],
  R.identity,
)(currentWorksheetId);

export const currentLocationId = createSelector(
  [fromRouter.paramLocationId, locationIdProp],
  (paramLocationId, locationIdProp) => {
    if (locationIdProp) return locationIdProp;
    if (paramLocationId) return paramLocationId;
  },
);

export const changeEntity = state => {
  return fromEntities.getEntity(state, 'change');
};

// --
// -- Plan App Summary - Base Selectors
// --

export const planAppSummaryGetEntityDetailFn = state => {
  return planAppId => fromEntities.getDetail(state, 'planAppSummary', planAppId);
};

export const planAppSummaryEntity = state =>
  fromEntities.getEntity(state, 'planAppSummary');

/**
 * Returns list of planAppSummary entities for current orgId sorted by id (most
 * recent first)
 */
export const planAppSummaryList = createSelector(
  [planAppSummaryEntity, orgId],
  (summaryEntity, currentOrgId) => {
    return R.pipe(
      R.pickBy(R.propEq('organizationId', currentOrgId)),
      R.values,
      sortByIdPropDesc,
    )(summaryEntity);
  },
);

/**
 * Returns true if any plans in the summary have an open state
 */
export const hasOpenPlanApp = createSelector(planAppSummaryList, R.any(isStateOpen));

/**
 * Returns most recent planAppSummary for current org.
 */
export const planAppSummaryMostRecent = createSelector(
  [planAppSummaryList],
  summaryList => {
    const isValidList = Array.isArray(summaryList) && !R.isEmpty(summaryList);
    if (!isValidList) return undefined;

    // 1. Sort so summary with largest id first
    // 2. Pull out first item (summary with largest id)
    // 3. Return id of that first item
    return R.head(summaryList);
  },
);

/**
 * Returns most recent planAppSummary for current org that is in one of the completed states
 */
export const planAppSummaryMostRecentCompleted = createSelector(
  [planAppSummaryList],
  R.pipe(
    R.filter(R.pipe(R.prop('state'), R.contains(R.__, PLAN_APP_STATES_COMPLETED))),
    R.head,
  ),
);

export const planAppSummaryMostRecentCompletedIsNoncompliant = createSelector(
  planAppSummaryMostRecentCompleted,
  R.pipe(R.propOr(null, 'state'), R.equals(planAppStates.NONCOMPLIANCE)),
);

/**
 * Returns planAppId of most recent planAppSummary for current org.
 */
export const planAppSummaryIdMostRecent = createSelector(
  [planAppSummaryMostRecent],
  R.path(['id']),
);

export const planAppSummaryMostRecentState = createSelector(
  [planAppSummaryMostRecent],
  R.path(['state']),
);

/**
 * Returns planAppId based on props set explicitly through a passed in prop or
 * route param.
 *
 * NOTE: Example of non-explicit source could be based on the most recent
 * planAppSummary in which it infers the id.
 */
export const planAppIdExplicit = createSelector(
  [planAppIdProp, fromRouter.paramPlanAppId],
  (propId, paramId) => coerceToInt(propId || paramId),
);

/**
 * Returns planAppId based on explicit props if available, otherwise returns id
 * of most recent planAppSummary for current org.
 *
 * NOTE: This selector falls back to using `mostRecentSummaryId` selector to
 * allow the use of Plan App selectors when there isn't an explicit planAppId
 * available. An example of such a case is for showing Change Request indicators
 * in the UI (e.g. sidebar) even when the user isn't in the app specific pages.
 */
export const planAppId = createSelector(
  [planAppIdExplicit, planAppSummaryIdMostRecent],
  (explicitId, mostRecentSummaryId) => {
    if (explicitId) return explicitId;
    if (mostRecentSummaryId) return mostRecentSummaryId;
  },
);

/**
 * Returns planAppSummary of current plan app.
 */
export const planAppSummary = createSelector(
  [planAppId, planAppSummaryGetEntityDetailFn],
  (entityId, getEntityDetail) => {
    return getEntityDetail(entityId);
  },
);

/**
 * Returns true if the planAppId is the current org's most recent.
 */
export const isPlanAppIdMostRecent = createSelector(
  [planAppId, planAppSummaryIdMostRecent],
  R.equals,
);

// export const planAppSummaryChangeRequestId = createSelector(
//   [planAppSummaryMostRecent],
//   R.path(['changeRequest']),
// );

// --
// -- Plan App - Base Selectors
// --

export const planAppGetEntityDetailFn = state => {
  return planAppId => fromEntities.getDetail(state, 'planApp', planAppId);
};

export const planAppSectionEntity = state =>
  fromEntities.getEntity(state, 'planAppSection');

export const planApp = createSelector(
  [planAppId, planAppGetEntityDetailFn],
  (entityId, getEntityDetail) => getEntityDetail(entityId),
);

export const planAppState = createSelector([planApp], R.path(['state']));

export const annualInspection = createSelector([planApp], R.path(['annualInspection']));

export const planPartialInspections = createSelector(
  [planApp],
  R.path(['partialInspections']),
);

export const annualInspectionSamples = createSelector(
  [annualInspection],
  R.path(['samples']),
);

export const qualificationAnswers = createSelector(
  [planApp],
  R.pathOr([], ['qualificationAnswers', 'values']),
);

export const qualificationQuestions = createSelector(
  [planApp],
  R.pipe(R.pathOr([], ['definition', 'qualifications', 'questions']), R.reject(R.isNil)),
);

export const qualificationQuestionsMap = createSelector(
  [qualificationQuestions],
  R.indexBy(R.prop('uuid')),
);

export const worksheetSections = createSelector(
  [planApp, planAppSectionEntity],
  (planApp, sectionMap) => {
    const planAppSectionIds = R.pathOr([], ['definition', 'sections'], planApp);

    return R.pipe(
      R.values,
      R.filter(section => R.contains(R.prop('uuid', section), planAppSectionIds)),
    )(sectionMap);
  },
);

export const locationTypes = createSelector(
  [planApp, planAppSectionEntity],
  (planApp, sectionMap) => {
    const planAppLocationIds = R.pathOr([], ['definition', 'locationTypes'], planApp);

    return R.pipe(
      R.values,
      R.filter(section => R.contains(R.prop('uuid', section), planAppLocationIds)),
    )(sectionMap);
  },
);

//
export const worksheetEntity = state => fromEntities.getEntity(state, 'worksheet');

// --
// -- PlanApp Selectors
// --
export const isPlanAppLocked = createSelector([planAppState], isStateLocked);
export const isPlanAppUnlocked = createSelector([planAppState], isStateUnlocked);
export const isPlanAppOpen = createSelector([planAppState], isStateOpen);
export const isPlanAppClosed = createSelector([planAppState], isStateClosed);

const isState = state => createSelector([planAppState], R.equals(state));

export const isStateInitialApplication = isState(planAppStates.INITIAL_APPLICATION);
export const isStateInitialApplicationSubmitted = isState(
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
);

export const isStateRevision = isState(planAppStates.REVISION);
export const isStateUpdateSubmitted = isState(planAppStates.REVISION_SUBMITTED);
export const isStateInitialReview = isState(planAppStates.INITIAL_REVIEW);
export const isStateInInitialReview = isState(planAppStates.IN_INITIAL_REVIEW);
export const isStateInspection = isState(planAppStates.INSPECTION);
export const isStateInInspection = isState(planAppStates.IN_INSPECTION);
export const isStateFinalReview = isState(planAppStates.FINAL_REVIEW);
export const isStateInFinalReview = isState(planAppStates.IN_FINAL_REVIEW);

export const isStateComplete = isState(planAppStates.COMPLETE);
export const isStateNoncompliance = isState(planAppStates.NONCOMPLIANCE);
export const isStateWithdrawn = isState(planAppStates.WITHDRAWN);

export const isStateReview = state =>
  isStateInitialReview(state) || isStateInspection(state) || isStateFinalReview(state);

// Change filters should only
// be visible for plans with status of
// ulocked for update, revision
// submitted,
// initial review,
// inspection,
// final review.
export const isStateUnderReview = (state, props) => {
  if (
    isStateComplete(state, props) ||
    isStateNoncompliance(state, props) ||
    isStateWithdrawn(state, props) ||
    isStateInitialApplication(state, props)
  )
    return false;
  return true;
};

// Plan progress
const isProgressCompleteZero = R.pathEq(['percentageComplete'], 0);
const isProgressCompleteNotZero = R.complement(isProgressCompleteZero);

export const isProgressNotStarted = createSelector([planApp], isProgressCompleteZero);
export const isProgressStarted = createSelector([planApp], isProgressCompleteNotZero);
export const isProgressComplete = R.pathEq(['percentageComplete'], 100);

// --
// -- Change Request
// --

export const changeRequestEntity = state =>
  fromEntities.getEntity(state, 'changeRequest');

/**
 * Returns changeRequest with id matching passed in changeRequestId prop value.
 */
export const changeRequestFromChangeRequestIdProp = createSelector(
  [changeRequestIdProp, changeRequestEntity],
  (changeRequestIdProp, changeRequestEntity) => {
    // Look up by key/id using changeRequestId prop
    if (changeRequestIdProp) {
      const entity = R.path([coerceToInt(changeRequestIdProp)], changeRequestEntity);
      if (entity) return entity;
    }
  },
);

/**
 * Returns changeRequest with applicationId matching value returned from
 * planAppId selector.
 */
export const changeRequestFromPlanAppId = createSelector(
  [planAppId, changeRequestEntity],
  (planAppId, changeRequestEntity) => {
    const changeRequest = R.pipe(
      R.values,
      R.find(R.propEq('applicationId', planAppId)),
    )(changeRequestEntity);

    if (changeRequest) return changeRequest;
  },
);

/**
 * For Change Requests we try to load changeRequest using the changeRequestId
 * which most usually comes from the planApp (but only when CR exist before
 * planApp load).
 *
 * Because there are times when the Change Request can be created after the
 * planApp is loaded, we will fall back to searching the Change Request entity
 * for one with a matching planAppId. This will handle the cases when the Change
 * Request entity exists in the store but the planApp entity detail hasn't been
 * updated.
 *
 * Do NOTE that this selector will return the chanceRequest that matches the
 * changeRequestId prop if one was passed. This allows explicitly loading a
 * specific Change Request by passing changeRequestId prop to a container.
 *
 * Look Up Priority:
 * 1. Look for changeRequest by changeRequestId prop when available
 * 2. Look for changeRequest by matching planAppId  to changeRequest.applicationId
 */
export const changeRequest = createSelector(
  [changeRequestFromChangeRequestIdProp, changeRequestFromPlanAppId],
  (changeRequestByIdProp, changeRequestByPlanAppId) => {
    if (changeRequestByIdProp) return changeRequestByIdProp;
    if (changeRequestByPlanAppId) return changeRequestByPlanAppId;
  },
);

/**
 * Because of the non-specific way we load the changeRequest in the above
 * changeRequest selector, the changeRequestId selector needs to be derived from
 * that. This is backwards to most common selectors.
 */
export const changeRequestId = createSelector([changeRequest], changeRequest => {
  if (changeRequest) return changeRequest.id;
  return undefined;
});

/**
 * Boolean selector indicating if a Change Request is in a state where users
 * should be notified about it.
 */
export const changeRequestIsOpen = createSelector([changeRequest], changeRequest => {
  if (changeRequest) {
    if (changeRequest.state === 'unsubmitted') return true;
    if (changeRequest.state === 'submitted') return true;
  }
  return false;
});

/**
 * Returns worksheetIds from the current Change Request if one exists.
 *
 * These are used to determine what worksheets to show when a CR is open. This
 * replaces the use of qualifiedWorksheetIds
 */
export const changeRequestWorksheetIds = createSelector(
  [changeRequest],
  changeRequest => {
    if (changeRequest) return changeRequest.worksheetIds;
    return undefined;
  },
);

export const planAppQualifiedWorksheetIds = createSelector(
  [planApp],
  R.pathOr([], ['worksheetIds']),
);

/**
 * Provides object map of changes for current change request keyed by changeId
 * e.g. { 1: {...change}, 3: {...change} }
 */
export const changeMap = createSelector(
  [changeRequestId, changeEntity],
  (changeRequestId, changeEntity) => {
    const isCurrentChange = R.propEq('changeRequestId', changeRequestId);
    return R.pickBy(isCurrentChange, changeEntity);
  },
);

/**
 * Provides list of Changes for current Change Request.
 * e.g. [change, change,...]
 */
export const changeList = createSelector([changeMap], R.values);

export const activityChecklistChange = createSelector(
  changeList,
  R.find(
    R.whereEq({
      context: 'main',
      type: 'plan',
    }),
  ),
);

/**
 * Return worksheetIds based on the current selected activity checklist. This
 * selector will use the worksheetIds on the Change Request if one exists,
 * otherwise it will use the worksheetIds on the planApp.
 */
export const qualifiedWorksheetIds = createSelector(
  [
    planAppQualifiedWorksheetIds,
    changeRequestWorksheetIds,
    changeRequestIsOpen,
    activityChecklistChange,
  ],
  (
    planAppQualifiedWorksheetIds,
    changeRequestWorksheetIds,
    changeRequestIsOpen,
    activityChecklistChange,
  ) => {
    // use the worksheetIds on the CR if one exists and is open
    if (changeRequestIsOpen) {
      if (!activityChecklistChange) {
        return changeRequestWorksheetIds;
      }

      switch (activityChecklistChange.state) {
        case 'accepted':
        case 'open':
          return changeRequestWorksheetIds;
        case 'rejected':
        default:
          return planAppQualifiedWorksheetIds;
      }
    }

    // no CR open so we use the worksheetIds from the planApp
    return planAppQualifiedWorksheetIds;
  },
);

/**
 * Provides a list of all worksheet ids for planApp that are in the order they
 * go from the first worksheet in the first section to the last worksheet in the
 * last section. This selector allows us to figure out the order of any list of
 * worksheets.
 */
export const orderedWorksheetIdList = createSelector(
  [worksheetSections],
  R.pipe(R.pluck('worksheets'), R.flatten),
);

export const qualifiedWorksheetSections = createSelector(
  [worksheetSections, qualifiedWorksheetIds],
  (sections, worksheetIds) => {
    return R.filter(R.pipe(R.prop('worksheets'), R.any(R.contains(R.__, worksheetIds))))(
      sections,
    );
  },
);

export const orderedQualifiedWorksheetIdList = createSelector(
  [qualifiedWorksheetIds, orderedWorksheetIdList],
  (qualifiedWorksheetIds, orderedWorksheetIdList) => {
    return R.filter(R.contains(R.__, qualifiedWorksheetIds))(orderedWorksheetIdList);
  },
);

/**
 *  Returns true if the worksheet has been added as part of the current change request
 */
export const isWorksheetNew = createSelector(
  [
    isStateInitialApplication,
    changeRequestId,
    planAppQualifiedWorksheetIds,
    changeRequestWorksheetIds,
    worksheetIdProp,
  ],
  (isInitial, crId, oldWSIds, newWSIds, wsId) => {
    if (isInitial || !crId) return false;

    const isOldWS = R.contains(wsId, oldWSIds);
    const isNewWS = R.contains(wsId, newWSIds);
    return isNewWS && !isOldWS;
  },
);

// --
// -- Worksheet Selectors
// --

export const prevWorksheetId = createSelector(
  [worksheetId, orderedQualifiedWorksheetIdList],
  (currentWorksheetId, allWorksheetIds) => {
    // When we don't have access to data we need always return undefined
    if (!currentWorksheetId || R.isEmpty(allWorksheetIds)) return undefined;

    return R.reduce(
      // When previous worksheetId found, short circuit reduce by calling
      // `reduced` with prevId.
      (prevId, currId) => {
        return currId === currentWorksheetId
          ? // When item equals currentWorksheetId the acc holds prevId
            R.reduced(prevId)
          : // Otherwise return currId so it's prevId in next iteration
            currId;
      },
      currentWorksheetId, // <= initial prevId value
      allWorksheetIds,
    );
  },
);

export const nextWorksheetId = createSelector(
  [worksheetId, orderedQualifiedWorksheetIdList],
  (currentWorksheetId, allWorksheetIds) => {
    // When we don't have access to data we need always return undefined
    if (!currentWorksheetId || R.isEmpty(allWorksheetIds)) return undefined;

    return R.reduce(
      // When next worksheetId found, short circuit reduce by calling
      // `reduced` with nextId.
      (prevId, currId) => {
        return prevId === currentWorksheetId
          ? // When acc equals currentWorksheetId the currId holds nextId
            R.reduced(currId)
          : // Otherwise return currId so it's prevId in next iteration
            currId;
      },
      undefined, // <= initial prevId value
      allWorksheetIds,
    );
  },
);

/**
 * Returns worksheet for current worksheetId.
 *
 * NOTE: Cached Selector: This selector has been created as a cached selector
 * based on the worksheetId. What allows us to use the selector for multiple
 * worksheetIds with a memoized result for each one.
 */
export const worksheet = createSelector([worksheetId, worksheetEntity], R.prop);

// --
// -- Worksheet Answer Selectors
// --

/**
 * Return id/key for worksheetAnswers
 */
const makeWorksheetAnswersId = (planAppId, worksheetId) =>
  makeCompositeKey(planAppId, worksheetId);

export const worksheetAnswerEntity = state =>
  fromEntities.getEntity(state, 'worksheetAnswer');

/***
 * Returns worksheetAnswersId for the current plan app worsheet
 */
export const worksheetAnswersId = createSelector(
  [planAppId, worksheetId],
  makeWorksheetAnswersId,
);

/***
 * Returns list of worksheetAnswersId's for current plan's active worksheets
 */
export const qualifiedWorksheetAnswersIdList = createSelector(
  [planAppId, qualifiedWorksheetIds],
  (planAppId, qualifiedWorksheetIds) => {
    return qualifiedWorksheetIds.map(worksheetId =>
      makeWorksheetAnswersId(planAppId, worksheetId),
    );
  },
);

export const qualifiedWorksheetAnswersMap = createSelector(
  [qualifiedWorksheetAnswersIdList, worksheetAnswerEntity],
  R.pick,
);

export const worksheetAnswers = createSelector(
  [worksheetAnswersId, worksheetAnswerEntity],
  R.propOr({}),
);

// --
// -- Notes
// --

export const notesEntity = state => fromEntities.getEntity(state, 'planAppNote');

export const notes = createSelector(
  [notesEntity, planAppId, questionIdProp, matrixRowIdProp, currentLocationId],
  (notesMap, planAppId, questionId, matrixRowId, locationId) => {
    const noteQuery = {};
    if (planAppId) noteQuery.applicationId = planAppId;
    if (questionId) noteQuery.uuid = questionId;
    if (matrixRowId) noteQuery.subUuid = matrixRowId;
    if (locationId) noteQuery.landId = locationId;

    return R.pipe(R.pickBy(R.whereEq(noteQuery)), R.values)(notesMap);
  },
);

// --
// -- Worksheet Question
// --

export const worksheetQuestionEntity = state =>
  fromEntities.getEntity(state, 'worksheetQuestion');

/**
 * Gets a worksheet question based on questionId
 */
export const question = createSelector([questionIdProp, worksheetQuestionEntity], R.prop);

/**
 * Gets a list of worksheet questions for current worksheet
 */
export const worksheetQuestionList = createSelector(
  [worksheet, worksheetQuestionEntity],
  (worksheet, wsQuestionEntity) => {
    if (!worksheet) return [];

    const questionIdList = R.path(['questions'], worksheet);

    return R.pipe(R.pick(questionIdList), R.values)(wsQuestionEntity);
  },
);

// --
// -- Change Request Change
// --

/**
 * Return Change based on changeId
 */
export const change = createSafeCachedSelector(
  [changeId, changeEntity],
  (changeId, changeEntity) => {
    return R.path([changeId], changeEntity);
  },
)(changeId);

/**
 * Return questionId for current change (based on changeId)
 */
export const changePlanAppId = createSafeCachedSelector(
  [change],
  R.path(['applicationId']),
)(changeId);

/**
 * Return worksheetId for current change (based on changeId)
 */
export const changeWorksheetId = createSafeCachedSelector(
  [change],
  R.path(['worksheetId']),
)(changeId);

/**
 * Return landId for current change (based on changeId)
 */
export const changeLocationId = createSafeCachedSelector(
  [change],
  R.path(['landId']),
)(changeId);

/**
 * Return questionId for current change (based on changeId)
 */
export const changeQuestionId = createSafeCachedSelector(
  [change],
  R.path(['questionId']),
)(changeId);

/**
 * Return worksheet for current change (based on changeId)
 */
export const changeWorksheet = createSafeCachedSelector(
  [changeWorksheetId, worksheetEntity],
  (worksheetId, worksheets) => {
    return R.path([worksheetId], worksheets);
  },
)(changeId);

/**
 * Return worksheet Name for current change (based on changeId)
 */
export const changeWorksheetName = createSafeCachedSelector(
  [changeWorksheet],
  R.path(['name']),
)(changeId);

/**
 * Return question for current change (based on changeId)
 */
export const changeQuestion = createSafeCachedSelector(
  [changeQuestionId, worksheetQuestionEntity],
  R.prop,
)(changeId);

/**
 * Return question Name for current change (based on changeId)
 */
export const changeQuestionName = createSafeCachedSelector(
  [changeQuestion],
  R.path(['name']),
)(changeId);

/**
 * Provides list of Change IDs for current Change Request.
 * e.g. [change, change,...]
 */
export const changeIdList = createSelector([changeList], R.map(R.prop('id')));

/**
 * List of all Changes for current Worksheet
 */
export const worksheetChangeList = createSelector(
  [worksheetId, planAppId, changeEntity],
  (worksheetId, planAppId, changeEntity) => {
    const isCurrentChange = R.whereEq({
      worksheetId,
      applicationId: planAppId,
    });
    const wsChangeMap = R.pickBy(isCurrentChange, changeEntity);
    const wsChangeList = R.values(wsChangeMap);
    return wsChangeList;
  },
);

/**
 * List of all Changes for current Worksheet Question
 */
export const questionChangeList = createSelector(
  [worksheetChangeList, questionIdProp],
  (changeList, questionId) => {
    return R.filter(R.propEq('questionId', questionId), changeList);
  },
);

/**
 * Get the change for given worksheet question id (filtered by matrix row if set)
 */
export const questionChange = createSelector(
  [worksheetChangeList, questionIdProp, matrixRowIdProp],
  (changeList, questionId, matrixRowId) => {
    return R.find(
      R.whereEq({
        questionId: questionId,
        matrixRowId: R.defaultTo(null, matrixRowId),
      }),
    )(changeList);
  },
);

/**
 * Get the change for matrix row deletion
 */
export const matrixRowDeletionChange = createSelector(
  [matrixRowIdProp, worksheetChangeList],
  (matrixRowId, changes) =>
    R.find(
      R.whereEq({
        type: 'matrix_row',
        action: 'deleted',
        matrixRowId,
      }),
    )(changes),
);

/** Get the change for a given worksheet (for not applicable value) */
export const worksheetChange = createSelector(
  [worksheetIdProp, worksheetChangeList],
  (worksheetId, changes) => {
    return R.pipe(
      R.find(
        R.whereEq({
          context: 'main',
          type: 'worksheet',
        }),
      ),
      R.defaultTo(null),
    )(changes);
  },
);

/** Get the appropriate 'is_not_applicable' value based on worksheet change's state */
export const isWorksheetNotApplicable = createSelector(
  [worksheetAnswers, worksheetChange],
  (wsAnswers, change) => {
    // When change doesn't exist, use worksheet NA value
    if (!change) {
      return R.pathOr(false, ['isNotApplicable'], wsAnswers);
    }

    // If change is in the correct state, use the 'new' value
    if (R.contains(change.state, ['open', 'accepted', 'applied'])) {
      return R.pathOr(false, ['new', 'isNotApplicable'], change);
    }

    return R.pathOr(false, ['old', 'isNotApplicable'], change);
  },
);

/**
 * Get the change id for given worksheet question id (filtered by
 * matrix row if set)
 */
export const questionChangeId = createSelector([questionChange], R.path(['id']));

/**
 * Return true if one or more Changes for current Change Request need to be
 * reviewed.
 */
export const changeListHasOpenChanges = createSelector(
  [changeList],
  R.pipe(R.filter(R.propEq('state', 'open')), R.isEmpty, R.not),
);

export const qualificationAnswersChangeList = createSelector(
  [changeList],
  R.pipe(
    // Qualification answer changes are stored under the 'plan' type
    R.filter(
      R.whereEq({
        context: 'main',
        type: 'plan',
      }),
    ),
    // There should only be one change in the list if it exists
    R.propOr(null, 0),
  ),
);

// --
// -- Change Comment
// --

export const changeCommentEntity = state => {
  return fromEntities.getEntity(state, 'changeComment');
};

/**
 * List of all Change Comments for current Change Request
 */
export const changeRequestCommentList = createSelector(
  [changeIdList, changeCommentEntity],
  (changeIdList, commentEntity) => {
    const isCurrentCR = R.propSatisfies(R.contains(R.__, changeIdList), 'changeId');
    const commentMap = R.pickBy(isCurrentCR, commentEntity);
    const commentList = R.values(commentMap);
    return commentList;
  },
);

/**
 * List of all Change Comment IDs for current Change Request
 */
export const changeRequestCommentIdList = createSelector(
  [changeRequestCommentList],
  commentList => {
    return R.pluck('id')(commentList);
  },
);

/**
 * List of all Change Comments for current Change
 */
export const changeCommentList = createSelector(
  [changeId, changeCommentEntity],
  (changeId, commentEntity) => {
    const isCurrentChange = R.propEq('changeId', changeId);
    const commentMap = R.pickBy(isCurrentChange, commentEntity);
    const commentList = R.values(commentMap);
    return commentList;
  },
);

/**
 * Return Change Comment based on changeCommentId
 */
export const changeComment = createSafeCachedSelector(
  [changeCommentId, changeCommentEntity],
  (changeCommentId, changeCommentEntity) => {
    return R.path([changeCommentId], changeCommentEntity);
  },
)(changeCommentId);

export const changeCommentNotifyAca = createSafeCachedSelector(
  [changeComment],
  R.pathEq(['notifyAca'], true),
)(changeCommentId);

export const changeCommentNotifyOrg = createSafeCachedSelector(
  [changeComment],
  R.pathEq(['notifyOrg'], true),
)(changeCommentId);

// --
// -- More Composite Selectors
// --

/**
 * Boolean selector indicating if a Change Request can be applied.
 *
 * Checks that Change Request is open and all of its changes have been reviewed
 * (are not open).
 */
export const changeRequestCanBeApplied = createSelector(
  [changeRequestIsOpen, isStateInFinalReview],
  (crIsOpen, isValidState) => {
    return crIsOpen && isValidState;
  },
);

/**
 * Boolean selector checking if a given matrix row id is in the original answer list
 */
export const matrixRowIsNewRow = createSelector(
  [worksheetAnswers, matrixRowIdProp],
  (wsAnswers, matrixRowId) =>
    R.pipe(
      R.path(['answers', 'matrixRows']),
      R.keys,
      R.contains(matrixRowId),
      R.not,
    )(wsAnswers),
);

/**
 * Finds any notes attached to the matrix cell
 */
export const matrixCellHasNotes = createSelector([], () => false);

/**
 * Finds any unread change comments on the matrix cell change
 */
export const questionHasCommentNotifications = createSelector(
  [changeCommentList, showAcaUI],
  (comments, isAca) => {
    const notifyProp = isAca ? 'notifyAca' : 'notifyOrg';
    return R.pipe(
      R.filter(R.propEq(notifyProp, true)),
      R.length,
      len => len > 0,
    )(comments);
  },
);

export const planAppInitialSummaryEntity = state =>
  fromEntities.getEntity(state, 'planAppInitialSummary');

// Used only on dashboard page so only the summarized id is available at this point
export const planAppInitialSummary = createSelector(
  [planAppSummaryIdMostRecent, planAppInitialSummaryEntity],
  R.prop,
);

// --
// -- Files
// --
// TODO Clean this up after release

export const qualifiedWorksheetAnswersFiles = createSelector(
  [qualifiedWorksheetAnswersMap],
  wsAnswers => {
    const values = R.pipe(
      R.values,
      R.chain(
        R.pipe(
          R.path(['answers', 'values']),
          R.values, // strip worksheet id
        ),
      ),
    )(wsAnswers);

    const matrixRowValues = R.pipe(
      R.values,
      R.chain(
        R.pipe(
          R.path(['answers', 'matrixRows']),
          R.values, // strip worksheet id
          R.chain(R.pipe(R.path(['values']), R.values)),
        ),
      ),
    )(wsAnswers);

    const allValues = R.concat(values, matrixRowValues);

    const files = R.pipe(
      R.filter(_isArray),
      R.flatten,
      R.filter(
        R.where({
          id: R.complement(R.isNil),
          name: R.complement(R.isNil),
        }),
      ),
    )(allValues);

    return files;
  },
);
