import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import shellAction from 'sow/actions/pure/shell';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate } from 'sow/store/helpers';
import { worksheetOverviewRoute } from 'sow/routes';
import QualificationChecklistForm from 'sow/components/organisms/QualificationChecklistForm';

const getPlanAppResource = orgId => resourceUpdate(`org/${orgId}/application`, 'planApp');
const getUpdateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => {
  const orgId = fromPlanApp.orgId(state, props);
  const planAppId = fromPlanApp.planAppId(state, props);
  const changeRequestId = fromPlanApp.changeRequestId(state, props);

  const { selectors: planAppSelectors } = getPlanAppResource(orgId);
  const { selectors: changeSelectors } = getUpdateChangeResource(
    orgId,
    planAppId,
    changeRequestId,
  );

  return {
    orgId,
    planAppId,
    isPlanAppUpdating: planAppSelectors.pending(state, props),
    isChangeUpdating: changeSelectors.pending(state, props),
    isNewApp: fromPlanApp.isStateInitialApplication(state, props),
    changeRequestId: fromPlanApp.changeRequestId(state, props),
    changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
    qualificationAnswersChange: fromPlanApp.qualificationAnswersChangeList(state, props),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { routerPush } = ownProps;

  const redirectToWorksheetOverview = (orgId, planAppId) =>
    routerPush(worksheetOverviewRoute(orgId, planAppId));

  return {
    notifyAutoSelectQuestions: () =>
      dispatch(
        shellAction.toast(
          'info',
          'This question requires another question that has been selected automatically.',
        ),
      ),
    updatePlanApp: (orgId, planAppId) => questionIds => {
      const { action } = getPlanAppResource(orgId, planAppId);
      return dispatch(
        action(planAppId, {
          ospApplication: {
            qualificationAnswers: { _schema: '1', values: questionIds },
          },
        }),
      );
    },
    updateQualificationAnswerChange: (orgId, planAppId, changeRequestId) => {
      const { action } = getUpdateChangeResource(orgId, planAppId, changeRequestId);
      return change => dispatch(action(null, { changes: [change] }));
    },
    redirectToWorksheetOverview: (orgId, planAppId) => {
      return () => redirectToWorksheetOverview(orgId, planAppId);
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  redirectToWorksheetOverview: dispatchProps.redirectToWorksheetOverview(
    stateProps.orgId,
    stateProps.planAppId,
  ),
  updateQualificationAnswerChange: dispatchProps.updateQualificationAnswerChange(
    stateProps.orgId,
    stateProps.planAppId,
    stateProps.changeRequestId,
  ),
  updatePlanApp: dispatchProps.updatePlanApp(stateProps.orgId, stateProps.planAppId),
});

const changeValuesPath = [
  'qualificationAnswersChange',
  'new',
  'qualificationAnswers',
  'values',
];

class QualificationChecklistFormContainer extends Component {
  state = {
    _dirty: false,
    answers: this.props.answers,
    qualificationAnswersChange: this.props.qualificationAnswersChange,
  };

  static getDerivedStateFromProps(props, state) {
    // If a change is created for the checklist, load it into this component's state
    if (!state.qualificationAnswersChange && props.qualificationAnswersChange) {
      return {
        qualificationAnswersChange: props.qualificationAnswersChange,
      };
    }

    return null;
  }

  isFormDirty = () => this.state._dirty;
  setFormClean = () => this.setState({ _dirty: false });
  setFormDirty = () => this.setState({ _dirty: true });

  hasUncheckedReferenceQuestions(refIds, answers) {
    return R.pipe(
      R.without(answers),
      R.isEmpty,
      R.not,
    )(refIds);
  }

  handleAnswersChange = question => this.handleChange(question, ['answers']);

  handleChange(question, statePath) {
    const answers = R.path(statePath, this.state);
    const { uuid, references } = question;
    const nextIds = R.append(uuid, references);

    this.setFormDirty();

    // Remove answer if already selected
    if (R.contains(uuid, answers)) {
      this.setState(prevState =>
        R.assocPath(
          statePath,
          R.without([uuid], R.path(statePath, prevState)),
          prevState,
        ),
      );
      return;
    }

    // Show notification if any other answers are auto-selected
    if (this.hasUncheckedReferenceQuestions(references, answers)) {
      this.props.notifyAutoSelectQuestions();
    }

    // Add new answer(s)
    this.setState(R.assocPath(statePath, R.union(answers, nextIds)));
  }

  handleClickSubmit = event => {
    if (event) event.preventDefault();
    this.submitUpdate();
  };

  handleClickSubmitNext = async event => {
    if (event) event.preventDefault();
    await this.submitUpdate();
    this.props.redirectToWorksheetOverview();
  };

  handleQualificationAnswersChangeChange = question =>
    this.handleChange(question, changeValuesPath);

  handleSubmitUpdateError = err => {
    throw err; // TODO add standard error alerting used throughout the app
  };

  isUpdating = () =>
    this.props.changeRequestIsOpen
      ? this.props.isChangeUpdating
      : this.props.isPlanAppUpdating;

  submitUpdate = async () => {
    // update already in progress
    if (this.isUpdating()) return;
    // nothing has changed on form to save
    if (!this.isFormDirty()) return;

    try {
      const respData = this.props.changeRequestIsOpen
        ? await this.updateChange()
        : await this.updatePlanApp();
      this.setFormClean();
      return respData;
    } catch (err) {
      this.handleSubmitUpdateError(err);
    }
  };

  /** Sends updated values as CR Change update */
  updateChange() {
    const { updateQualificationAnswerChange } = this.props;
    const answers = R.path(changeValuesPath, this.state);

    return updateQualificationAnswerChange({
      ...this.props.qualificationAnswersChange,
      action: 'updated',
      new: { qualification_answers: { _schema: '1', values: answers } },
    });
  }

  /** Sends updated values PlanApp update (org's initial plan app)*/
  updatePlanApp() {
    return this.props.updatePlanApp(this.state.answers);
  }

  render() {
    const { questions, isNewApp, changeRequestIsOpen } = this.props;
    const { answers, qualificationAnswersChange } = this.state;

    return (
      <QualificationChecklistForm
        questions={questions}
        answers={answers}
        isUpdating={this.isUpdating()}
        isNewApp={isNewApp}
        changeRequestIsOpen={changeRequestIsOpen}
        qualificationAnswersChange={qualificationAnswersChange}
        onChange={this.handleAnswersChange}
        onClickSubmit={this.handleClickSubmit}
        onClickSubmitNext={this.handleClickSubmitNext}
        onQualificationAnswersChangeChange={this.handleQualificationAnswersChangeChange}
      />
    );
  }
}

QualificationChecklistFormContainer.propTypes = {
  // Array of qualification questions that can be selected
  questions: PropTypes.arrayOf(sowTypes.planAppQualificationQuestionType).isRequired,
  // Array of question uuids that have been selected
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  notifyAutoSelectQuestions: PropTypes.func.isRequired,
  updateQualificationAnswerChange: PropTypes.func,
  updatePlanApp: PropTypes.func,
  redirectToWorksheetOverview: PropTypes.func.isRequired,
  isChangeUpdating: PropTypes.bool.isRequired,
  isPlanAppUpdating: PropTypes.bool.isRequired,
  isNewApp: PropTypes.bool.isRequired,
  qualificationAnswersChange: sowTypes.planAppChangeType,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  routerPush: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  // this shouldn't be here but other stuff would have to be refactored otherwise
  connect(
    null,
    { routerPush },
  ),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(QualificationChecklistFormContainer);
