import React, { Fragment } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import './ChangesListItem.module.css';
import {
  locationWorksheetMatrixRowScrollRoute,
  locationWorksheetMatrixRowScrollRouteLinkToObject,
  locationWorksheetQuestionScrollRoute,
  planAppWorksheetMatrixRowScrollRoute,
  planAppWorksheetMatrixRowScrollRouteLinkToObject,
  planAppWorksheetQuestionScrollRoute,
} from 'sow/components/atoms/ScrollTarget/scrollRoutes';
import Block from 'sow/components/atoms/Block';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import ChangeListItemComments from 'sow/components/organisms/PlanAppChangeRequestManager/ChangeListItemComments';
import { worksheetRoute, locationRoute, checklistRoute } from 'sow/routes';

const ChangeType = styled(Block)`
  background-color: ${({ theme, state, blocked }) => {
    if (blocked) return theme.ospChanges.blocked.primary;
    return theme.ospChanges[state].primary;
  }};
  color: ${({ theme, state, blocked }) => {
    if (blocked) return theme.ospChanges.blocked.secondary;
    return theme.ospChanges[state].secondary;
  }};
`;

const changeStateDisplayText = state => {
  switch (state) {
    case 'open':
      return 'PENDING';
    case 'rejected':
      return 'DISCARDED';
    case 'not_applied':
      return 'NOT APPLIED';
    case 'requested_change':
      return 'TO-DO';
    case 'accepted':
    case 'applied':
    default:
      return state.toUpperCase();
  }
};

const createLinkTo = listItem => {
  if (listItem.type === 'plan')
    return checklistRoute(listItem.orgId, listItem.applicationId);
  if (listItem.landId) {
    if (listItem.matrixRowId) {
      if (listItem.type === 'matrix_row') {
        return locationWorksheetMatrixRowScrollRoute(
          listItem.orgId,
          listItem.applicationId,
          listItem.landId,
          listItem.matrixRowId,
        );
      }
      return {
        ...locationWorksheetMatrixRowScrollRouteLinkToObject(
          listItem.orgId,
          listItem.applicationId,
          listItem.landId,
          listItem.matrixRowId,
        ),
        state: { openModalId: listItem.matrixRowId },
      };
    } else {
      return listItem.questionId
        ? locationWorksheetQuestionScrollRoute(
            listItem.orgId,
            listItem.applicationId,
            listItem.landId,
            listItem.questionId,
          )
        : locationRoute(listItem.orgId, listItem.applicationId, listItem.landId);
    }
  } else {
    if (listItem.matrixRowId) {
      if (listItem.type === 'matrix_row') {
        return planAppWorksheetMatrixRowScrollRoute(
          listItem.orgId,
          listItem.applicationId,
          listItem.worksheetId,
          listItem.matrixRowId,
        );
      }
      return {
        ...planAppWorksheetMatrixRowScrollRouteLinkToObject(
          listItem.orgId,
          listItem.applicationId,
          listItem.worksheetId,
          listItem.matrixRowId,
        ),
        state: { openModalId: listItem.matrixRowId },
      };
    } else {
      return listItem.questionId
        ? planAppWorksheetQuestionScrollRoute(
            listItem.orgId,
            listItem.applicationId,
            listItem.worksheetId,
            listItem.questionId,
          )
        : worksheetRoute(listItem.orgId, listItem.applicationId, listItem.worksheetId);
    }
  }
};

const fetchQuestionName = (questionName, listItem) => {
  if (questionName)
    return `${questionName} ${listItem.action && `(answer ${listItem.action})`}`;

  if (listItem.questionName) return listItem.questionName;

  if (listItem.type === 'plan') return 'Activities Checklist';

  if (listItem.type === 'matrix_row') return 'Matrix row (row removed)';

  if (listItem.type === 'self') return 'Location Updated';

  if (!listItem.questionName) return 'All worksheet questions have been marked to-do.';
};

const ChangesListItem = ({
  listItem,
  orgId,
  questionName,
  comments,
  showComments,
  toggleComments,
  handleClearTodo,
}) => {
  return (
    <Fragment>
      <Block className="list-item-block">
        <Link to={createLinkTo({ ...listItem, orgId })} className="change-question">
          {fetchQuestionName(questionName, listItem)}
        </Link>
        <Block className="change-type-block">
          <ChangeType
            className="change-type-pill"
            state={listItem.state}
            blocked={listItem.blocked}
          >
            <FontAwesome className="change-type-icon" icon="circle" />
            {changeStateDisplayText(listItem.state)}
            {listItem.blocked && (
              <InfoTooltip overlay="This change is currently blocked by another change. It can be updated, but will not be applied until the other change is resolved.">
                <Block style={{ fontSize: '10px', display: 'inline' }}>(BLOCKED)</Block>
              </InfoTooltip>
            )}
          </ChangeType>
        </Block>
        {handleClearTodo && (
          <Block className="clear-block">
            <FontAwesome
              className="fa-regular"
              icon="circle-xmark"
              onClick={handleClearTodo}
            />
          </Block>
        )}
        {!R.isEmpty(comments) && (
          <Block>
            <ChangeListItemComments
              changeState={listItem.state}
              comments={comments}
              showComments={showComments}
              toggleComments={toggleComments}
            />
          </Block>
        )}
      </Block>
    </Fragment>
  );
};

export default ChangesListItem;
